import { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import Logo from '@/components/PageContainer/components/Logo';
import { Modal, Button, Space, Dropdown, App, GetProps, Input, Form, Checkbox } from 'antd';
import Iconfont from '@/components/iconfont';
import Image from '@/components/image';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import websiteAxios from '@/serve/websiteAxios';
import { token, isLoginAtom, userInfo } from '@/store/atoms/global';
import { CODE_STATUS } from '@/constants';
import { CaretDownOutlined } from '@ant-design/icons';
import defaultPhoto from "@/public/assets/images/defaultPhoto.png";
import scan from "@/public/assets/images/scan.png";
import styles from "./index.module.css";
import { useRouter } from 'next/router';
import Link from '@/components/link';
import useDebounceFn from '../useDebounceFn';

interface EnLoginForm { email: string; code: string; agreement: boolean }

interface UseLoginModalProps {
    // 登录按钮
    loginButton?: React.ReactNode;
    loginButtonProps?: GetProps<typeof Button>;
    disabledClosable?: boolean;
    // 展示用户信息
    loginDropdown?: React.ReactNode;
    id: number;
}
/**
 * @description: 登录模块
 * @param {UseLoginModalProps} param1
 * @return {*}
 */
const useLoginModal = ({ loginButton, loginButtonProps, loginDropdown, disabledClosable, id }: UseLoginModalProps) => {
    const { locale } = useRouter();
    const { message } = App.useApp();
    // 中文版登录弹窗
    const [loginVisible, setLoginVisible] = useState<boolean>(false);
    // 英文版登录弹窗
    const [loginVisibleEn, setLoginVisibleEn] = useState<boolean>(false);
    // 登录key
    const [loginKey, setLoginKey] = useState<string>('');
    // 小程序码图片地址
    const [qrCodeUrl, setQrCodeUrl] = useState<string>('');
    // 小程序码是否失效
    const [expired, setExpired] = useState<boolean>(false);
    // setTokenKey
    const setTokenKey = useSetAtom(token);
    // 用户信息
    const [user, setUser] = useAtom(userInfo);
    // 是否登录
    const isLogin = useAtomValue(isLoginAtom);
    // 轮询
    const interval = useRef<NodeJS.Timeout | undefined>(undefined);

    // 刷新二维码
    const refreshRQCode = () => {
        setQrCodeUrl('');
        setLoginKey('');
        beforeLogin(true);
    };
    // 登录前的方法 用于获取登录所需信息 （中文版获取二维码）
    const beforeLogin = async (refresh = false) => {
        // 只有中文才有小程序码登录
        if (locale === 'zh') {
            const res = await websiteAxios.post<any, { qrCodeUrl: string; loginKey: string; }>('/api/login?endpoint=getQrCode').catch((error) => {
                console.error('error', error);
                if (!error.response) return;
                const { data } = error.response;
                if (data) {
                    message.error(data.msg);
                }
            });
            if (!res) return;
            setQrCodeUrl(res.qrCodeUrl);
            setLoginKey(res.loginKey);
            if (!refresh) {
                setLoginVisible(true);
            } else {
                setExpired(false);
            }
        } else {
            setLoginVisibleEn(true);
        }
    };

    useEffect(() => {
        const waveElement = document.querySelector(`.${styles.wave}`) as HTMLElement;
        if (loginVisible) {
            // 二维码失效 不再轮询 不再显示波浪动画
            if (expired) return;
            // 波浪动画
            if (waveElement) {
                waveElement.style.removeProperty("display");
                let animationCount = 0;
                waveElement.addEventListener("animationiteration", () => {
                    animationCount++;
                    if (animationCount % 3 === 0) {
                        waveElement.style.display = "none";
                    }
                });
            }

            if (!loginKey) return;
            // 轮询登录状态
            interval.current = setInterval(async () => {
                const token = await websiteAxios.post<any, any>('/api/login?endpoint=queryLoginKey', { loginKey }).catch((error) => {
                    const { data } = error.response;
                    switch (data.code) {
                        case CODE_STATUS.QRCODE_EXPIRED:
                            clearInterval(interval.current);
                            setExpired(true);
                            break;
                        default:
                            message.error(data.msg);
                            break;
                    }
                });
                if (!token) return;
                if (token) {
                    setTokenKey(token);
                    clearInterval(interval.current);
                    setLoginVisible(false);
                    message.success('扫码登录成功');
                }
            }, 2000);

        } else {
            // 关闭登录弹窗 移除动画监听 清除轮询
            if (waveElement) {
                waveElement.removeEventListener("animationiteration", () => { });
            }
            interval.current && clearInterval(interval.current)
        }
    }, [loginVisible, loginKey, expired, interval.current]);

    // 登录弹出层
    const loginModuleRender = useMemo(() => (
        <Modal
            centered
            title={null}
            footer={null}
            open={loginVisible}
            onCancel={() => {
                setQrCodeUrl('');
                setLoginKey('');
                setExpired(false);
                setLoginVisible(false)
            }}
        >
            {JSON.stringify(disabledClosable)}
            <div className={styles.loginTitle}>微信扫码登录</div>
            {expired ? (
                <div className={styles.expired} onClick={refreshRQCode}>
                    <Iconfont type="icon-shuaxin1" className={styles.icon} />
                    <span>二维码已失效，点击请刷新</span>
                </div>
            ) : (
                <div className={styles['scan-wave']}>
                    {qrCodeUrl && <Image
                        src={qrCodeUrl}
                        alt="二维码"
                        className={styles.qrCode}
                        width={200}
                        height={200}
                    />}
                    <Image
                        src={scan}
                        className={styles.wave}
                        alt=""
                        width={200}
                    />
                </div>
            )}
            <div className={styles.loginTip}>中国罕见病联盟官方小程序</div>
        </Modal>
    ), [loginVisible, qrCodeUrl, expired]);
    // send按钮文本
    const [codeText, setCodeText] = useState('Send');
    // 倒计时
    const countRef = useRef<number>(60);
    // 英文登录表单
    const [enLoginForm] = Form.useForm<EnLoginForm>();
    const sendLoading = useRef<boolean>(false);
    // 邮箱是否重复
    const [emailRepeat, setEmailRepeat] = useState<boolean>(false);
    // 发送验证码
    const loginSendCode = async () => {
        // 防止频繁点击
        if (countRef.current < 60 || sendLoading.current) return;
        sendLoading.current = true;
        const value = enLoginForm.getFieldValue('email');
        const res = await websiteAxios.post<any, any>(`/api/common?endpoint=sendEmailCode&email=${value}`).catch((error) => {
            console.error('error', error);
            if (!error.response) return;
            const { data } = error.response;
            if (data) {
                message.error(data.msg);
            }
        });
        sendLoading.current = false;
        if (!res) return;
        message.success('验证码已发送');
        const timer = setInterval(() => {
            if (countRef.current <= 0) {
                setCodeText('Resend');
                countRef.current = 60;
                clearInterval(timer);
                return;
            }
            countRef.current -= 1
            setCodeText(`${countRef.current}s`);
        }, 1000);
    }
    //增加防抖
    const { run } = useDebounceFn(loginSendCode, { wait: 700 });
    // 提交登录
    const onFinish: GetProps<typeof Form<any>>['onFinish'] = async (values) => {
        if (!values.agreement) {
            message.error('Please agree to the user registration agreement');
            return;
        }
        const res = await websiteAxios.post<any, any>(`/api/common?endpoint=emailLogin&email=${values.email}&code=${values.code}`).catch((error) => {
            console.error('error', error);
            if (!error.response) return;
            const { data } = error.response;

            if (data && data.code === '6200') {
                setEmailRepeat(true);
                return;
            } else {
                message.error(data.msg);

            }
        });
        if (!res) return;
        setEmailRepeat(false)
        setTokenKey(res.tokenKey);
        setLoginVisibleEn(false);
    }
    // 英文版登录弹出层
    const loginModuleRenderEn = useMemo(() => (
        <Modal
            centered
            title={null}
            footer={null}
            open={loginVisibleEn}
            closable={disabledClosable}
            maskClosable={disabledClosable}
            keyboard={disabledClosable}
            onCancel={() => {
                setLoginVisibleEn(false)
                enLoginForm.resetFields();
            }}
        >
            <div className={styles['enLoginModuleWrapper']}>
                <Logo />
                <h3>Log in</h3>
                <Form form={enLoginForm} onFinish={onFinish}>
                    <Form.Item name="email" rules={[{ type: 'email', message: 'Please enter the correct mailbox' }]}>
                        <Input placeholder="Please enter your email" />
                    </Form.Item>
                    <Form.Item name="code" rules={[{ len: 6, message: 'Please enter the correct verification code' }]}>
                        <Input
                            placeholder="Please enter your verification code in your email"
                            suffix={
                                <button type="button" className={styles['codeBtn']} onClick={run}>{codeText}</button>
                            }
                        />
                    </Form.Item>
                    <Form.Item noStyle name="agreement" valuePropName='checked'>
                        <Checkbox className={styles['checkbox']}>Agree to
                            <Link className={styles['link']} href={`/conference/agreement/${id}`} locale="en" target="_blank">《User Registration Agreement》</Link>
                        </Checkbox>
                    </Form.Item>
                    <Form.Item>
                        <Button className={styles['submit']} htmlType='submit' type="primary">Submit</Button>
                    </Form.Item>
                    {emailRepeat && (
                        <div className={styles['error']}>
                            The email address already exists. If you have any questions, please contact the official email address（<a href="mailto:support@chard.org.cn">support@chard.org.cn</a>）
                        </div>
                    )}
                </Form>
            </div>
        </Modal>
    ), [loginVisibleEn, codeText, emailRepeat, disabledClosable]);

    // 退出
    const items = [
        {
            key: '1',
            // 暂时先使用locale判断是否是中文 后续可以改为国际化
            label: locale === 'zh' ? '退出' : 'Log out',
            onClick: () => {
                setTokenKey(null);
                setUser(null);
            }
        }
    ];
    // 登录展示用户信息
    const loginDropdownRender = useCallback((currUserInfo: User) => {
        const userName = locale === 'zh' ? currUserInfo.doctorName : currUserInfo.doctorEmail;
        return (
            loginDropdown ? loginDropdown : (
                <Dropdown menu={{ items }}>
                    <a onClick={(e) => e.preventDefault()}>
                        <Space>
                            {
                                currUserInfo.userPhoto
                                    ? <Image src={currUserInfo.userPhoto} className={styles.photo} alt="" width={24} height={24} />
                                    : <Image src={defaultPhoto} className={styles.photo} alt="默认头像" width={24} height={24} />
                            }
                            <span className={styles.name}>{userName}</span>
                            <span className={styles.icon}>
                                <CaretDownOutlined />
                            </span>
                        </Space>
                    </a>
                </Dropdown>
            )
        )
    }, [locale, loginDropdown]);

    // 登录按钮
    const loginInfoRender = useMemo(() => {
        if (!isLogin || !user) {
            const { children, ...restProps } = loginButtonProps || {};
            return loginButton ?
                loginButton
                : (
                    <Button
                        size="small"
                        type="primary"
                        onClick={() => beforeLogin()}
                        {...restProps}
                    >
                        {children || '登录'}
                    </Button>
                )
        };
        return loginDropdownRender(user);
    }, [isLogin, user, loginButton, loginButtonProps, loginDropdownRender]);

    return {
        loginVisible: locale === 'zh' ? loginVisible : loginVisibleEn,
        beforeLogin,
        LoginInfoContent: loginInfoRender,
        LoginModuleContent: locale === 'zh' ? loginModuleRender : loginModuleRenderEn,
    }
};

export default useLoginModal;