import React from 'react';
// 价格类型
interface Price {
    price: string;
    startTime: string;
    endTime: string;
    endTimeStr: string;
    title: string;
    inTime: boolean;
}
// 线路信息类型
interface LineInfo {
    time: string;
    place: string;
    officialEmail: string;
    officialPhone: string;
    priceList: Price[];
    bottomLogo: string;
    bottomSlogan: string;
}
interface LayoutContextProps {
    headerBgImage: string;
    onlineInfo: LineInfo;
    offlineInfo: LineInfo;
    logo: string;
    emailPath: string;
    agendaPath: string;
    articleId: number;
    indexQrCode: string;
    disabledClosable?: boolean;
}

const ConferenceLayoutContext = React.createContext<LayoutContextProps>({
    headerBgImage: '',
    onlineInfo: {
        time: '',
        place: '',
        officialEmail: '',
        officialPhone: '',
        priceList: [],
        bottomLogo: '',
        bottomSlogan: '',
    },
    offlineInfo: {
        time: '',
        place: '',
        officialEmail: '',
        officialPhone: '',
        priceList: [],
        bottomLogo: '',
        bottomSlogan: '',
    },
    logo: '',
    emailPath: '',
    agendaPath: '',
    articleId: 0,
    indexQrCode: '',
    disabledClosable: true,
});

export default ConferenceLayoutContext;