import { FC, useContext, useMemo } from 'react';

// ========================= 组件 =========================
import Link from '@/components/link';
import AddressItem from '../AddressItem';
import { Divider, Space } from 'antd';

// ========================= 数据交互 =========================

// ========================= 全局数据 =========================

// ========================= 常量 =========================

// ========================= 工具 =========================
import classnames from 'classnames';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import ConferenceLayoutContext from '@/context/conference';

// ========================= 样式 =========================
import styles from './index.module.css';

// ========================= 变量 =========================

// ========================= 类型 =========================

interface ConferenceHeaderProps {
    // 点击注册按钮事件
    onRegistrationClick?: () => void;
    // 是否展示语言切换
    showLocale?: boolean;
    // 是否展示注册按钮
    showRegistration?: boolean;
    disabledRegistration?: boolean;
    // 子元素
    children: React.ReactNode;
}

const ConferenceHeader: FC<ConferenceHeaderProps> = ({ children, showRegistration = true, showLocale = true, disabledRegistration, onRegistrationClick }) => {
    // 这里的asPath是当前页面的路径，locale是当前的语言 注意：锚链接改变不会重置asPath地址
    const { asPath, locale } = useRouter();
    
    // asPath移除hash值
    const asPathWithoutHash = useMemo(() => asPath.replace(/#.*$/, ''), [asPath]);
    // 会议模块的国际化
    const { t: conferenceT } = useTranslation('conference');
    // 公共模块的国际化
    const { t: commonT } = useTranslation('common');

    const { headerBgImage, onlineInfo, offlineInfo, logo, } = useContext(ConferenceLayoutContext);

    return (
        <header
            className={classnames(styles['header'], styles['base-header'])}
            style={{ backgroundImage: `url(${headerBgImage})` }}
        >
            <img
                className={styles['logo']}
                src={logo}
                alt="罕见病大会Logo"
            />
            {locale === 'zh' ? (
                <div className={styles['header-info']}>
                    <AddressItem title={`线上: ${onlineInfo.place}`} subTitle={onlineInfo.time} />
                    <AddressItem title={`线下: ${offlineInfo.place}`} subTitle={offlineInfo.time} />
                </div>
            ) : (
                <div className={styles['header-info']}>
                    <AddressItem isEmail title={`${offlineInfo.time}`} subTitle={offlineInfo.officialEmail} />
                </div>
            )}

            <div className={styles['login-wrapper']}>
                <Space split={<Divider style={{ borderColor: '#ffffff' }} type="vertical" />}>
                    {showRegistration && (
                        <button className={styles['registration']} disabled={disabledRegistration} onClick={onRegistrationClick}>
                            {conferenceT('signUp')}
                        </button>
                    )}

                    <div className={styles['login-inner']}>
                        {/* 切换语言 将hash值也带上 */}
                        {showLocale && (
                            <>
                                {locale === 'en' && <Link href={asPathWithoutHash} locale="zh">{commonT('localeText')}</Link>}
                                {locale === 'zh' && <Link href={asPathWithoutHash} locale="en">{commonT('localeText')}</Link>}
                            </>
                        )}

                        {children}
                    </div>
                </Space>
            </div>
        </header>

    )
}
export default ConferenceHeader;