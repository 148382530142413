import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";

// Global atoms
export const token = atomWithStorage<string | null>("WebsiteTK", null, {
    getItem(key: string, initialValue:any) {
        const item = localStorage.getItem(key);
        return Promise.resolve(item ? JSON.parse(item) : initialValue);
    },
    setItem(key: string, newValue: any) {
        if (!newValue) {
            return Promise.resolve(localStorage.removeItem(key));
        } else {
            return Promise.resolve(
                localStorage.setItem(key, JSON.stringify(newValue))
            );
        }
    },
    removeItem(key: string) {
        return Promise.resolve(localStorage.removeItem(key));
    },
});

// 登录状态 根据token判断
export const isLoginAtom = atom((get) => {
    return !!get(token);
});

// User atoms
export const userInfo = atomWithStorage<User | null>("WebsiteUserInfo", null, {
    getItem(key: string, initialValue: any) {
        const item = localStorage.getItem(key);
        return Promise.resolve(item ? JSON.parse(item) : initialValue);
    },
    setItem(key: string, newValue: any) {
        if (!newValue) {
            return Promise.resolve(localStorage.removeItem(key));
        } else {
            return Promise.resolve(
                localStorage.setItem(key, JSON.stringify(newValue))
            );
        }
    },
    removeItem(key: string) {
        return Promise.resolve(localStorage.removeItem(key));
    },
});
