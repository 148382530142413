import { FC } from 'react';

// ========================= 组件 =========================
import Image from '../image';

// ========================= 数据交互 =========================

// ========================= 全局数据 =========================
import email from '@/public/assets/images/email.png';
import agenda from '@/public/assets/images/agenda.png';

// ========================= 常量 =========================

// ========================= 工具 =========================

// ========================= 样式 =========================
import styles from './index.module.css';

// ========================= 变量 =========================

// ========================= 类型 =========================

interface AppEnKFProps {
    emailPath: string;
    agendaPath: string;
}

const AppEnKF: FC<AppEnKFProps> = ({ emailPath, agendaPath }) => {
    return (
        <section className={styles['kf-en']}>
            {
                emailPath && (
                    <a href={`mailto:${emailPath}`}>
                        <div className={styles['kf-en-item']}>
                            <Image src={email} alt='' className={styles['kf-en-icon']} />
                            <span className={styles['kf-en-text']}>Email</span>
                        </div>
                    </a>
                )
            }
            {
                agendaPath && (
                    <a href={agendaPath} target="_blank" download>
                        <div className={styles['kf-en-item']}>
                            <Image src={agenda} alt='' className={styles['kf-en-icon']} />
                            <span className={styles['kf-en-text']}>Agenda</span>
                        </div>
                    </a>
                )
            }
        </section>
    );
}
export default AppEnKF;