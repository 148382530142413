import { useEffect, useState, useCallback, useRef } from 'react';
import { Form, Button, Select, Input, Modal, App } from 'antd';
import { useAtom } from 'jotai';
import debounce from 'lodash/debounce';
import websiteAxios from '@/serve/websiteAxios';
import { token, userInfo } from '@/store/atoms/global';
import { USER_TYPE } from '@/constants';
import HospitalSelect from './Hospital';
import styles from "@/components/PageContainer/PageContainer.module.css";

interface Illness {
    id: number;
    chineseName: string;
}

const useUserForm = () => {

    const { message } = App.useApp();

    const [user, setUser] = useAtom(userInfo);
    const [tokenKey] = useAtom(token);
    const [userVisible, setUserVisible] = useState<boolean>(false);
    const [userForm] = Form.useForm();
    const [options, setOptions] = useState<Illness[]>([]);
    const fetchRef = useRef(0);

    const loadOptions = (value: string) => {
        fetchRef.current += 1;
        const fetchId = fetchRef.current;
        setOptions([]);

        websiteAxios.get<any, any>('/api/common?endpoint=getRareDiseaseList', { params: { keyword: value }, headers: { tokenKey } })
            .then((res) => {
                if (fetchId !== fetchRef.current) {
                    return;
                }
                setOptions(res);
            }).catch((error) => {
                const { data } = error.response;
                if (data) {
                    message.error(data.msg);
                }
            });
    };

    const debounceFetcher = debounce(loadOptions, 200);

    useEffect(() => {
        if (!user) return;
        const values = {
            ...user,
            doctorCurrentHospital: user.doctorCurrentHospitalId ? { value: user.doctorCurrentHospitalId, label: user.doctorCurrentHospital } : undefined,
            illness: user.confirmedDisease?.map((item) => {
                if (item.id) {
                    return { value: item.id, label: item.name }
                }
                return { value: item.name, label: item.name }
            }) ?? []
        }
        userForm.setFieldsValue(values);
    }, [user]);

    const onUserFinish = useCallback(async ({
        doctorCurrentHospital,
        illness,
        ...values
    }: UserForm) => {
        const params: User = {
            ...values,
        }
        if (doctorCurrentHospital) {
            params.doctorCurrentHospital = doctorCurrentHospital?.label;
            params.doctorCurrentHospitalId = doctorCurrentHospital?.value;
        }
        if (illness) {
            params.confirmedDisease = illness?.map((item) => {
                if (item.value === item.label) {
                    return { name: item.value }
                }
                return { id: item.value, name: item.label }
            }) ?? []
        }
        const res = await websiteAxios.post<any, any>('/api/user?endpoint=userUpdate', params, { headers: { tokenKey } }).catch((error) => {
            const { data } = error.response;
            if (data) {
                message.error(data.msg);
            }
        });
        if (res) {
            setUserVisible(false);
            if (!user) return;
            setUser({ userPhoto: user.userPhoto, doctorName: values.doctorName });
        }
    }, [tokenKey]);

    const UserInfoContent = (
        <Modal
            centered
            title={null}
            footer={null}
            open={userVisible}
            onCancel={() => setUserVisible(false)}
        >
            <div className={styles.loginTitle}>用户信息</div>
            <Form layout="vertical" form={userForm} onFinish={onUserFinish}>
                <Form.Item label="用户类型" name="status" rules={[{ required: true, message: '请选择用户类型' }]}>
                    <Select placeholder="请选择用户类型" options={USER_TYPE} />
                </Form.Item>
                <Form.Item label="姓名" name="doctorName">
                    <Input placeholder="请输入姓名" />
                </Form.Item>
                <Form.Item dependencies={['status']}>
                    {({ getFieldValue }) => {
                        const status = getFieldValue('status');
                        switch (status) {
                            case 1:
                            case 2:
                            case 3:
                                return (
                                    <Form.Item label="所属医院" name="doctorCurrentHospital">
                                        <HospitalSelect />
                                    </Form.Item>
                                );
                            case 5:
                            case 8:
                                return (
                                    <Form.Item label="工作单位" name="companyOrOrganization">
                                        <Input placeholder="请输入工作单位" />
                                    </Form.Item>
                                );
                            case 6:
                                return (
                                    <Form.Item label="大学名称" name="universityName">
                                        <Input placeholder="请输入大学名称" />
                                    </Form.Item>
                                );
                            case 7:
                                return (
                                    <Form.Item label="确诊疾病" name="illness">
                                        <Select
                                            labelInValue
                                            mode="tags"
                                            style={{ width: '100%' }}
                                            placeholder="请选择确诊疾病"
                                            onSearch={debounceFetcher}
                                            filterSort={(option, optionB, info) => {
                                                if (info.searchValue === optionB.chineseName) {
                                                    return -1;
                                                }
                                                return option.chineseName.localeCompare(optionB.chineseName);
                                            }}
                                            fieldNames={{ label: 'chineseName', value: 'id' }}
                                            options={options}
                                        />
                                    </Form.Item>
                                );
                            default:
                                return null;
                        }
                    }}
                </Form.Item>
                <div className={styles.submit}>
                    <Button type="primary" style={{ width: '50%' }} htmlType="submit">提交</Button>
                </div>
            </Form>
        </Modal>
    );

    return {
        userVisible,
        setUserVisible,
        UserInfoContent,
    }
};

export default useUserForm;