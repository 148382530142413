import { FC, ReactNode, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import Image from "@/components/image";
import Link from "@/components/link";
import styles from "./index.module.css";
import NavItem from "../NavItem";

interface CenterReviewProps {
    centerReview: boolean;
    setCenterReview: (value: boolean) => void;
    children?: ReactNode;
}
/**
 * @description: 中心评审导航和弹出层内容
 * @param {*} param1
 * @return {*}
 */
const CenterReview: FC<CenterReviewProps> = ({ centerReview, children, setCenterReview }) => {
    const CssTransitionRef = useRef<HTMLDivElement>(null);

    const centerReviewModalRender = (
        <CSSTransition
            key={"中心评审CssTransition"}
            nodeRef={CssTransitionRef}
            in={centerReview}
            timeout={200}
            unmountOnExit
            appear
            classNames="fade-in"
        >
            <section
                ref={CssTransitionRef}
                className={styles.float_wrapper}
            >
                <div className={styles.center_review_wrapper}>
                    <Image
                        src="https://hjblm-platform.chard.org.cn/rare_disease/image/202309/gVAt0t_1695283864278.png"
                        className={styles.banner}
                        width={1272}
                        height={256}
                        alt="中心评审"
                    />
                    <div className={styles.intro_wrapper}>
                        <p className={styles.intro}>
                            为推动多发性硬化(MS)和视神经脊髓炎谱系疾病(NMOSD)诊疗,促进单病种多中心的诊疗规范与协作,在国家卫生健康委医院管理研究所和国家罕见病质控中心指导下,罕见病联盟/北京罕见病诊疗与保障学会发起“MS/NMOSD中心建设”项目,以评促改，持续改善，逐步实现MS/NMOSD患者早期规范诊疗和分级协作管理。
                        </p>
                        <div className={styles.qrcode_box}>
                            <Image
                                className={styles.qrcode}
                                src="https://hjblm-platform.chard.org.cn/rare_disease/image/202311/wbB3F6_1699326072681.jpg"
                                width={200}
                                height={200}
                                alt="中心评审小程序二维码"
                            />
                            <p className={styles.tip}>微信扫码提交申请意向</p>
                        </div>
                    </div>
                    <Link href="https://app.chard.org.cn/app-login-admin-web/#/applist" className={styles.btn} target="_blank">
                        立即访问
                    </Link>
                </div>
            </section>
        </CSSTransition>
    )

    return (
        <NavItem
            isActive={centerReview}
            onClick={() => setCenterReview(true)}
            modal={centerReviewModalRender}
        >
            {children}
        </NavItem>
    );
};

export default CenterReview;