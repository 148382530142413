import { isUrl } from "@/utils/validate";
import { type NextRouter, useRouter } from "next/router";
import { useCallback } from "react";
import i18nConfig from '@/next-i18next.config';
import type { UrlObject } from "url";

type Url = string | UrlObject;
// (url: Url, as?: Url, options?: TransitionOptions): Promise<boolean>;
export default function useRouterPush() {
    const router = useRouter();
    const push: NextRouter['push'] = useCallback((url, as, options) => {
        let path: Url = url
        console.log('process.env.TARGET ', process.env.TARGET );
        // 判断是否为测试环境
        let assetPrefix = process.env.TARGET === 'test' ? `/rare-disease2-web` : '';
        if (process.env.TARGET === 'test') {
            // 判断是否添加语言前缀
            assetPrefix = i18nConfig.i18n.defaultLocale === options?.locale || !options?.locale ? assetPrefix : `${assetPrefix}/${options?.locale}`;
        }
        if (typeof url === "string") {
            // 判断href是否是以http或https开头的
            path = `${!isUrl(url) ? assetPrefix : ""}${url}`;
        } else if (url?.pathname) {
            path = {
                ...url,
                href: `${!isUrl(url?.pathname) ? assetPrefix : ""}${url.pathname}`
            };
        }

        return router.push(path, as, options);
    }, [router]);
    return push
}