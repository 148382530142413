import { useEffect, useRef, useCallback } from 'react';

interface UseScrollIntoViewOptions {
    behavior?: ScrollBehavior;
    block?: ScrollLogicalPosition;
    inline?: ScrollLogicalPosition
    onScrollEnd?: () => void;
}

interface UseScrollIntoViewResult {
    scrollToElement: (target?: HTMLElement) => void;
}
// 滚动到指定元素 hook 并且监听滚动结束
const useScrollIntoView = (options: UseScrollIntoViewOptions = {}): UseScrollIntoViewResult => {
    const { behavior = 'smooth', block = 'start', inline = 'nearest', onScrollEnd } = options;
    const scrollTimeoutRef = useRef<NodeJS.Timeout | null>(null);

    const scrollToElement: UseScrollIntoViewResult['scrollToElement'] = useCallback((target) => {
        if (target) {
            target.scrollIntoView({ behavior, block, inline });
        }
    }, [behavior, block, inline]);

    useEffect(() => {
        const handleScroll = () => {
            if (scrollTimeoutRef.current) {
                clearTimeout(scrollTimeoutRef.current);
            }
            scrollTimeoutRef.current = setTimeout(() => {
                if (onScrollEnd) {
                    onScrollEnd();
                }
            }, 100); // 100ms 后判断滚动结束
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            if (scrollTimeoutRef.current) {
                clearTimeout(scrollTimeoutRef.current);
            }
        };
    }, [onScrollEnd]);

    return { scrollToElement };
};

export default useScrollIntoView;