import { FC, ReactNode } from "react";
import classNames from "classnames";
import styles from "./index.module.css";

interface NavItemProps {
    // 是否激活
    isActive: boolean;
    // 点击事件
    onClick?: () => void;
    // 子元素
    children?: ReactNode;
    // 浮动层
    modal?: ReactNode;
}
/**
 * @description: 导航栏子项
 * @param {*} param1
 * @return {*}
 */
const NavItem: FC<NavItemProps> = ({ isActive, onClick, children, modal }) => {
    return (
        <li
            className={classNames(styles.nav_item, { [styles.active]: isActive })}
            onClick={onClick}
        >
            <span className={styles.nav_item_text}>{children}</span>
            {modal}
        </li>
    );
};

export default NavItem;