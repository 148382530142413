import { FC } from 'react';

// ========================= 组件 =========================

// ========================= 数据交互 =========================

// ========================= 全局数据 =========================

// ========================= 常量 =========================

// ========================= 工具 =========================
import { useRouter } from 'next/router';
import classnames from 'classnames';

// ========================= 样式 =========================
import styles from './index.module.css';

// ========================= 变量 =========================

// ========================= 类型 =========================

interface AddressItemProps {
    title: string;
    subTitle: string;
    isEmail?: boolean;
}

const AddressItem: FC<AddressItemProps> = ({ title, subTitle, isEmail = false }) => {

    const { locale } = useRouter();

    return (
        <div
            className={
                classnames(styles['address-wrapper'],
                    {
                        [styles['en']]: locale === 'en',
                    }
                )
            }
        >
            <span className={styles['title']}>{title}</span>
            <span className={styles['subTitle']}>
                {
                    isEmail ? (
                        <a
                            href={`mailto:${subTitle}`}
                            className={styles['email']}
                        >
                            {subTitle}
                        </a>
                    ) : subTitle
                }
            </span>
        </div>
    );
}
export default AddressItem;