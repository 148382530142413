import { FC, ReactNode, useCallback, useEffect, useState } from "react";
import { useRouter } from "next/router";
import Header from "./components/MainHeader";
import CenterReview from "./components/CenterReview";
import UpwardsNav from "./components/UpwardsNav";
import NavItem from "./components/NavItem";
import useLogin from "@/hooks/useLogin";
import styles from "./PageContainer.module.css";
import Link from "../link";

interface MainPageContainerProps {
    navData: Nav[];
    footer: ReactNode;
    children?: (info: PageContainerInfo) => ReactNode;
}

const MainPageContainer: FC<MainPageContainerProps> = ({ navData, footer, children }) => {
    const { query, asPath } = useRouter();
    const [upwards, setUpwards] = useState<boolean>(false);
    const [centerReview, setCenterReview] = useState<boolean>(false);

    const { beforeLogin, LoginInfoContent, LoginModuleContent, UserInfoContent } = useLogin();

    const isActive = useCallback((item: Nav) => {
        const isDetailPage = (type: string) =>
            asPath.startsWith("/detail") &&
            (item.link.includes(`/${type}`) || item.navigationList?.some(v => v.link.includes(`/${type}`))) &&
            asPath.includes(`/${type}`);
        if (asPath === "/" && item.link === "/") return true;
        if (item.link === '/') return false;
        if (asPath.startsWith(item.link)) return true;
        if (item.navigationList?.length && item.navigationList?.some((subItem) => asPath.includes(subItem.link))) return true;
        if (['activity', 'news', 'member', 'database', 'building'].some(isDetailPage)) return true;
        return false;
    }, [asPath]);

    useEffect(() => {
        const handleScroll = () => {
            const header = document.getElementById("header");
            if (header && header.getBoundingClientRect().top < -header.clientHeight) {
                setUpwards(false);
                setCenterReview(false);
            }
        };
        if (query?.review) {
            setCenterReview(true);
        }
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const getHeaderLink = (item: Nav) => {
        if (item.navigationList?.length && item.navigationList[0].link !== '/index') {
            return item.link + item.navigationList[0].link;
        }
        return item.link;
    };

    useEffect(() => {
        function handleClickOutside(event: any) {
            const checkAndSet = (id: string, setFunc: (value: boolean) => void) => {
                const element = document.getElementById(id);
                if (element && element.contains(event.target)) {
                    return true;
                }
                if (!element?.contains(event.target)) {
                    setFunc(false);
                }
                return false;
            };

            if (checkAndSet('popups_link', setUpwards)) {
                setCenterReview(false);
                return;
            } else if (checkAndSet('center_review', setCenterReview)) {
                setUpwards(false);
                return;
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [centerReview, upwards]);

    return (
        <>
            <Header loginBtnContent={LoginInfoContent} >
                <nav className={styles.PageHead__nav_wrapper}>
                    <ul className={styles.PageHead__nav}>
                        {navData?.map((item) => {
                            if (item.link === "#popups_1") {
                                return (
                                    <UpwardsNav key={item.link} upwards={upwards} setUpwards={setUpwards} >
                                        <span aria-current={isActive(item) ? "page" : undefined}>
                                            {item.name}
                                        </span>
                                    </UpwardsNav>
                                );
                            }
                            if (item.link === "#center_review") {
                                return (
                                    <CenterReview key={item.link} centerReview={centerReview} setCenterReview={setCenterReview} >
                                        <span aria-current={isActive(item) ? "page" : undefined}>
                                            {item.name}
                                        </span>
                                    </CenterReview>
                                );
                            }
                            return (
                                <NavItem
                                    key={item.link}
                                    isActive={!upwards && !centerReview && isActive(item)}
                                >
                                    <Link
                                        href={getHeaderLink(item)}
                                        aria-current={isActive(item) ? "page" : undefined}
                                    >
                                        {item.name}
                                    </Link>
                                </NavItem>
                            );
                        })}
                    </ul>
                </nav>
            </Header>
            {/* 弹出层虚化 */}
            {upwards || centerReview ? <div aria-hidden={true} id={styles.mask}></div> : null}
            {/* <div aria-hidden={true} className={styles.navPlaceholder}></div> */}
            {/* 登录弹出层模块 */}
            {LoginModuleContent}
            {/* 用户信息注册模块弹出层 */}
            {UserInfoContent}
            {/* 页面内容 */}
            {children && children({ onLogin: beforeLogin })}
            {/* 页脚 */}
            {footer}
        </>
    );
};

export default MainPageContainer;