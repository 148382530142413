import { useEffect, useCallback } from 'react';
import { useAtom, useSetAtom } from 'jotai';
import websiteAxios from '@/serve/websiteAxios';
import { token, userInfo } from '@/store/atoms/global';
import { CODE_STATUS } from '@/constants';
/**
 * @description: 获取用户信息
 * @return {*}
 */
const useFetchUserInfo = () => {
    const [tokenKey, setTokenKey] = useAtom(token);
    const setUser = useSetAtom(userInfo);

    const getUserInfo = useCallback(async (tokenKey: string) => {
        const userRes = await websiteAxios.post<any, any>('/api/user?endpoint=userInfo', '', { headers: { tokenkey: tokenKey } }).catch((error) => {
            console.log('error', error);
            if (!error?.response?.data) {
                console.error(error.message);
                return;
            }
            const { data } = error.response;
            if (data.code === CODE_STATUS.TOKEN_EXPIRED) {
                setTokenKey(null);
                setUser(null);
            }
        });
        if (userRes) {
            setUser(userRes);
        }
    }, [setTokenKey, setUser]);

    useEffect(() => {
        if (tokenKey) {
            getUserInfo(tokenKey);
        }
    }, [tokenKey, getUserInfo]);
};

export default useFetchUserInfo;