import { GetRef, Select } from "antd";
import { useEffect, useRef, useState } from "react";
import websiteAxios from "@/serve/websiteAxios";
import debounce from "lodash/debounce";
import styles from "@/components/PageContainer/PageContainer.module.css";

export interface HospitalSelectProps {
    id?: string;
    value?: number;
    onChange?: (value: number) => void;
}
type SelectRefType = GetRef<typeof Select>; // BaseSelectRef

export interface Hospital {
    id: number;
    name: string;
    provinceName?: string;
    cityName?: string;
    areaName?: string;
    idcard?: string;
    areacode?: number;
    level?: number;
    levelnum?: number;
    bednum?: number;
    abbreviation?: null;
    oldName?: null;
    intro?: null;
    hospitalType?: number;
    provinceCode?: number;
    cityCode?: number;
    orderNum?: number;
}
const HospitalSelect = ({ id, value: propsValue, onChange }: HospitalSelectProps) => {
    // 所属医院
    const [options, setOptions] = useState<Hospital[]>([]);
    const [searchValue, setSearchValue] = useState<string>('');
    const [value, setValue] = useState<number | undefined>(propsValue);
    useEffect(() => {
        setValue(propsValue);
    }, [propsValue]);
    const fetchRef = useRef(0);
    const selectRef = useRef<SelectRefType>(null);

    const loadOptions = (value: string) => {
        fetchRef.current += 1;
        const fetchId = fetchRef.current;
        setOptions([]);
        setSearchValue(value);

        websiteAxios.get<any, any>('/api/common?endpoint=getHospital', { params: { keyword: value } })
            .then((res) => {

                if (fetchId !== fetchRef.current) {
                    // for fetch callback order
                    return;
                }
                setOptions(res);
            }).catch((error) => {
                console.error('error', error);
            })
    };

    const debounceFetcher = debounce(loadOptions, 200);

    const onClickAddHospital = () => {
        setOptions([{ id: 0, name: searchValue }])
        setValue(0);
        onChange && onChange(0);
        selectRef.current?.blur();
    }

    const addHospital = (
        <div className={styles.addHospital}>
            无搜索结果，<span className={styles.blue} onClick={onClickAddHospital}>点击新增</span>
        </div>
    )

    return (
        <Select
            showSearch
            labelInValue
            id={id}
            ref={selectRef}
            value={value}
            placeholder="请选择所属医院"
            filterOption={false}
            getPopupContainer={triggerNode => triggerNode.parentElement}
            onSearch={debounceFetcher}
            notFoundContent={!options.length && searchValue ? addHospital : null}
            onChange={onChange}
        >
            {
                options.map((option) => (
                    <Select.Option key={option.id} value={option.id}>
                        {option.name}
                    </Select.Option>
                ))
            }
        </Select>
    )
}

export default HospitalSelect;