import { FC, ReactNode, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import Image from "@/components/image";
import Link from "@/components/link";
import styles from "./index.module.css";
import upLogo from "/public/assets/images/upLogo.png";
import NavItem from "../NavItem";
import classnames from "classnames";

const upwardsItems = [
    {
        image: "https://hjblm-platform.chard.org.cn/rare_disease/image/20233/ycjcxm.png",
        title: "罕见病多学科诊疗项目",
        content: "罕见病多学科诊疗项目包括开展疑难罕见病多学科诊疗、典型病例培训材料制作和多学科诊疗网络平台建设",
    },
    {
        image: "https://hjblm-platform.chard.org.cn/rare_disease/image/20233/mdt_project.png",
        title: "遗传检测项目",
        content: "包括罕见病患者遗传检测和遗传检测标准制定",
    },
    {
        image: "https://hjblm-platform.chard.org.cn/rare_disease/image/20233/train.png",
        title: "罕见病诊疗能力培训",
        content: "包括罕见病单病种培训和专题类培训",
    },
];

// 渲染国家财政专项Item的内容
const UpwardsItem = (item: {
    image: string;
    title: string;
    content: ReactNode;
}) => (
    <>
        <div className={styles.upImage_wrapper}>
            <Image
                src={item.image}
                alt={item.title}
                fill
                className={styles.upImage}
            />
        </div>
        <h3>{item.title}</h3>
        <p>{item.content}</p>
    </>
);


interface UpwardsNavProps {
    upwards: boolean;
    setUpwards: (value: boolean) => void;
    children?: ReactNode;
}
/**
 * @description: 国家财政专项导航和弹出层内容
 * @param {*} param1
 * @return {*}
 */
const UpwardsNav: FC<UpwardsNavProps> = ({ upwards, children, setUpwards }) => {
    const CssTransitionRef = useRef<HTMLDivElement>(null);

    const upwardsModalRender = (
        <CSSTransition
            key={"国家财政专项CssTransition"}
            nodeRef={CssTransitionRef}
            in={upwards}
            timeout={200}
            unmountOnExit
            appear
            classNames="fade-in"
        >
            <section
                ref={CssTransitionRef}
                className={classnames(styles.float_wrapper, styles.upwards)}
                style={{
                    backgroundImage: `url(https://hjblm-platform.chard.org.cn/rare_disease/image/20233/upwards_bg.png)`,
                }}
            >
                <div className={styles.upwards_wrapper}>
                    <Image
                        src={upLogo}
                        className={styles.upLogo}
                        alt="国家财政专项upwards"
                        width={565}
                    />
                    <p className={styles.intro}>
                        为减轻国内罕见病患者及家庭临床诊治的经济负担，同时推进国内罕见病临床诊疗水平的进一步提高，由中央财政安排中央专项彩票公益金，支持北京协和医院牵头开展，面向国内罕见病患群体的“中央专项彩票公益金支持罕见病诊疗水平能力提升项目”。
                    </p>
                    <h2>项目主要包括以下工作</h2>
                    <ul className={styles.up_list}>
                        {upwardsItems.map((item, index) => (
                            <li key={index}>
                                <UpwardsItem
                                    image={item.image}
                                    title={item.title}
                                    content={item.content}
                                />
                            </li>
                        ))}
                    </ul>
                    <div className={styles.btnGroup}>
                        <Link href="https://upwards.chard.org.cn/" target="_blank">
                            立即访问
                        </Link>
                        <Link href='/list/news/15/77'>
                            财政专项动态
                        </Link>
                    </div>
                </div>
            </section>
        </CSSTransition>
    )

    return (
        <NavItem
            isActive={upwards}
            onClick={() => setUpwards(true)}
            modal={upwardsModalRender}
        >
            {children}
        </NavItem>
    );
};

export default UpwardsNav;