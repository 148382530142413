import React, { useEffect, useRef } from "react";

import iconfont from "../../public/assets/iconfont/iconfont";

type SvgProps = {
  type: string;
  className?: string;
  style?: React.CSSProperties;
} & React.SVGProps<SVGSVGElement>;

function Getsvg({ type, className, style, ...props }: SvgProps) {
  const svgXML = useRef<SVGSVGElement>(null);
  useEffect(() => {
    if (iconfont) {
      const domparser = new DOMParser();
      const resXML = domparser.parseFromString(iconfont, "text/xml");
      if (!type) return;
      const zeroXML = resXML.querySelector("#" + type);
      if (zeroXML && svgXML.current) {
        svgXML.current.innerHTML = zeroXML.innerHTML;
        svgXML.current.setAttribute("id", zeroXML.getAttribute("id") ?? "");
        svgXML.current.setAttribute(
          "viewBox",
          zeroXML.getAttribute("viewBox") ?? ""
        );
      }
    }
  }, [type]);

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <svg
        className={`icon ${className && className !== undefined ? className : ""
          }`}
        ref={svgXML}
        style={style}
        {...props}
      ></svg>
    </div>
  );
}

export default Getsvg

// type IconProps = {
//   type: string;
//   className?: string;
//   style?: React.CSSProperties;
// } & React.HTMLAttributes<HTMLElement>;

// const Icon: React.FC<IconProps> = ({ type, className, style, ...props }) => {
//   return (
//     <i className={`iconfont ${type} ${className}`} style={style} {...props}></i>
//   );
// };

// export default Icon;