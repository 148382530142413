import useFetchUserInfo from './useFetchUserInfo';
import useLoginModal from './useLoginModal';
import useUserForm from './useUserForm';
import { GetProps, Button } from 'antd';

interface UseLoginProps {
    loginButtonProps?: GetProps<typeof Button>
    loginButton?: React.ReactNode;
    loginDropdown?: React.ReactNode;
    disabledClosable?: boolean;
    id: number;
}

interface Login {
    loginVisible: boolean;
    LoginInfoContent: React.ReactNode;
    LoginModuleContent: React.ReactNode;
    UserInfoContent: React.ReactNode;
    userVisible: boolean;
    beforeLogin: () => void;
    setUserVisible: (visible: boolean) => void;
}

const defaultOpions: UseLoginProps = {
    loginButton: undefined,
    loginDropdown: undefined,
    loginButtonProps: undefined,
    disabledClosable: true,
    id: 0,
};


export default function useLogin(options?: UseLoginProps): Login {
    const { loginButton, loginDropdown, loginButtonProps, disabledClosable, id } = { ...defaultOpions, ...options };
    // 这个 hook 用于获取用户信息并处理相关逻辑。
    useFetchUserInfo();
    // 这个 hook 用于处理登录模态框的逻辑。
    const { loginVisible, beforeLogin, LoginInfoContent, LoginModuleContent } = useLoginModal({
        loginButton,
        loginButtonProps,
        loginDropdown,
        disabledClosable,
        id
    });
    // 这个 hook 用于处理用户信息表单的逻辑。
    const { userVisible, setUserVisible, UserInfoContent } = useUserForm();

    return {
        loginVisible,
        LoginInfoContent,
        LoginModuleContent,
        UserInfoContent,
        userVisible,
        beforeLogin,
        setUserVisible,
    };
}